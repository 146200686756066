

export class FirestoreCache {
  private static context = {
    cache: {
      apolloCache: new Map()
    }
  };
  public static getDataOrNull(data: any): object | null {
    if (data) {
      return data;
    }
    return null;
  }
  public static cacheBuster(collectionPath?: string, documentId?: string): void {
    this.context.cache.apolloCache.clear();
  }
  public static queryDatabase(
    documentRef: any,
    skipCache?: boolean
  ): Promise<any> {
    let q = null;

    if (!documentRef) {
      return null;
    }
    // const cache: Cache
    // if (!this.context) {
    //   console.warn('no context read: ', documentRef.path);
    //   return documentRef.get();
    // }

    // const cacheKey = documentRef.id;

    // const apolloCacheItem = this.context.cache.apolloCache.get(cacheKey);
    // if (apolloCacheItem) {
    //   // tslint:disable-next-line: ban
    //   // console.log('apollo cache hit', documentRef.path);
    //   return apolloCacheItem;
    // }

    if (this.context.cache.apolloCache.has(documentRef.id) && !Boolean(skipCache)) {
      q = this.context.cache.apolloCache.get(documentRef.id);

      // tslint:disable-next-line: ban
      // console.log('cache hit: ', documentRef.path);
    } else {
      q = documentRef
        .get()
        .then((data) => {
          if (data.exists) {
            return { id: data.id, ...data.data() };
          } else {
            console.log('data not existant: ', documentRef.path);
            return null;
          }
        })
        .catch((err: any): null => {
          // tslint:disable-next-line: ban
          console.log(err);
          return null;
        });

      // tslint:disable-next-line: ban
      // console.log('direct read: ', documentRef.path);

      this.context.cache.apolloCache.set(documentRef.id, q);
    }

    // console.log('Q: ', q)
    return q;
  }
}
