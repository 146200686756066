import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import moment from 'moment';
import { map, take } from 'rxjs/operators';
import { FirestoreCache } from './firestoreCache.util';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  public filter: any = {
    orders: '',
    emails: '',
    customers: '',
    dolmetscher: '',
    dolmetschCategory: '',
    customer: '',
    language: '',
    dolmetschType: '',
    month: '',
    year: moment().format('YYYY')
  };

  private cache: any = [];
  private promiseCache: any = [];
  constructor(private afs: AngularFirestore, private http: HttpClient) {
    // this.fixOrders()
  }

  getOrderList() {
    return this.afs
      .collection('order', (ref) =>
        ref
          .where('statusConfirmed', '==', false)
          .where('active', '==', true)
          .orderBy('dateFrom')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          console.log('changes');
          return changes.map((c) => ({
            id: c.payload.doc.id,
            change: c.type,
            ...(c.payload.doc.data() as any)
          }));
        })
      );
  }

  getOrderListOfSupervisor(customerId: string) {
    return this.afs
      .collection('order', (ref) =>
        ref
          // .where('statusConfirmed','==',false)
          .where('customer', '==', this.afs.collection('customer').doc(customerId).ref)
          .where('active', '==', true)
          .orderBy('dateFrom', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          console.log('changes');
          return changes.map((c) => ({
            id: c.payload.doc.id,
            change: c.type,
            ...(c.payload.doc.data() as any)
          }));
        })
      );
  }

  getCustomerListOfSupervisor(email: string) {
    console.log(email);
    return this.afs
      .collection('customer', (ref) => ref.where('supervisors', 'array-contains', email))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          console.log('changes: ', changes);
          return changes.map(
            (c) =>
              ({
                id: c.payload.doc.id,
                change: c.type,
                ...(c.payload.doc.data() as any)
              } as any)
          );
        })
      );
  }

  getOrderArchiveList(
    dateFrom: Date,
    dateTo: Date,
    customerId: string,
    dolmetschCategoryId: string,
    languageId: string,
    dolmetschTypeId: string,
    noshow: boolean
  ) {
    console.log('FirestoreService -> getOrderArchiveList -> getOrderArchiveList');
    // return this.afs.collection('order', ref => ref.where('dateFrom','>=',dateFrom).where('dateFrom','<=',dateTo).orderBy('dateFrom')).snapshotChanges()
    console.log('🚀 ~ file: firestore.service.ts:116 ~ FirestoreService ~ .collection ~ dateFrom', dateFrom);
    return (
      this.afs
        .collection('order', (ref) => {
          let q = ref
            .where('dateFrom', '>=', dateFrom)
            .where('dateFrom', '<=', dateTo)
            .where('active', '==', true);
          if (customerId) {
            q = q.where('customerId', '==', customerId);
          }
          if (dolmetschCategoryId) {
            q = q.where('dolmetschCategoryId', '==', dolmetschCategoryId);
          }
          if (languageId) {
            q = q.where('languageId', '==', languageId);
          }
          if (dolmetschTypeId) {
            q = q.where('dolmetschTypeId', '==', dolmetschTypeId);
          }
          if (noshow) {
            q = q.where('noshow', '==', noshow);
          }
          q = q.orderBy('dateFrom');

          return q;
        })
        .snapshotChanges()

        // return this.afs.collection('order', ref => ref).snapshotChanges()
        // return this.afs.collection('order', ref => ref.where('active','==',true).orderBy('dateFrom')).snapshotChanges()
        // return this.afs.collection('order', ref => ref.where('statusExported','==',true).where('active','==',true).orderBy('dateFrom')).snapshotChanges()
        .pipe(
          // take(1),
          map((changes) =>
            changes.map((c) => ({
              id: c.payload.doc.id,
              change: c.type,
              ...(c.payload.doc.data() as any)
              //region: this.resolveReference(c.payload.doc.data()['region'], true),
              // dolmetschType: this.resolveReference(c.payload.doc.data()['dolmetschType'], true),
              // dolmetschCategory: this.resolveReference(c.payload.doc.data()['dolmetschCategory'], true),
              // dolmetscher: this.resolveReference(c.payload.doc.data()['dolmetscher'], true),
              // language: this.resolveReference(c.payload.doc.data()['language'], true),
              // customer: this.resolveReference(c.payload.doc.data()['customer'], true),
            }))
          )
        )
    );
  }

  // App PW eqkfrtouwkurnfqt

  getOrderConfirmedList(
    dateFrom: Date,
    dateTo: Date,
    customerId: string,
    dolmetschCategoryId: string,
    languageId: string,
    dolmetschTypeId: string,
    noshow: boolean
  ) {
    return this.afs
      .collection('order', (ref) => {
        let q = ref
          .where('statusConfirmed', '==', true)
          .where('statusExported', '==', false)
          .where('dateFrom', '>=', dateFrom)
          .where('dateFrom', '<=', dateTo)
          .where('active', '==', true);

        if (customerId) {
          q = q.where('customerId', '==', customerId);
        }
        if (dolmetschCategoryId) {
          q = q.where('dolmetschCategoryId', '==', dolmetschCategoryId);
        }
        if (languageId) {
          q = q.where('languageId', '==', languageId);
        }
        if (dolmetschTypeId) {
          q = q.where('dolmetschTypeId', '==', dolmetschTypeId);
        }
        if (noshow) {
          q = q.where('noshow', '==', noshow);
        }

        // .limit(500)
        q = q.orderBy('dateFrom');

        return q;
      })
      .snapshotChanges()
      .pipe(
        take(1),
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            change: c.type,
            ...(c.payload.doc.data() as any)
            //region: this.resolveReference(c.payload.doc.data()['region'], true),
            // dolmetschType: this.resolveReference(c.payload.doc.data()['dolmetschType'], true),
            // dolmetschCategory: this.resolveReference(c.payload.doc.data()['dolmetschCategory'], true),
            // dolmetscher: this.resolveReference(c.payload.doc.data()['dolmetscher'], true),
            // language: this.resolveReference(c.payload.doc.data()['language'], true),
            // customer: this.resolveReference(c.payload.doc.data()['customer'], true),
          }))
        )
      );
  }
  getOrderDetail(id) {
    // return this.afs.collection('dolmetscher', ref => ref.where('region', '==', this.afs.doc('region/'+region).ref)).snapshotChanges()
    return this.afs
      .collection('order')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((c) => {
          const data: any = {
            id: c.payload.id,
            ...(c.payload.data() as any),
            region: this.resolveReference((c.payload.data() as any)?.region, true),
            dolmetschType: this.resolveReference((c.payload.data() as any)?.dolmetschType, true),
            dolmetschCategory: this.resolveReference((c.payload.data() as any)?.dolmetschCategory, true),
            dolmetscher: this.resolveReference((c.payload.data() as any)?.dolmetscher, true),
            language: this.resolveReference((c.payload.data() as any)?.language, true),
            customer: this.resolveReference((c.payload.data() as any)?.customer, true)
          };
          return data;
        })
      );
  }

  getRegionList() {
    return this.afs
      .collection('region', (ref) => ref.orderBy('title'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  getDolmetschTypeList() {
    return this.afs
      .collection('dolmetschType', (ref) => ref.orderBy('title'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }
  getDolmetschCategoryList() {
    return this.afs
      .collection('dolmetschCategory', (ref) => ref.orderBy('title'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  getDolmetscherList() {
    // return this.afs.collection('dolmetscher', ref => ref.where('region', '==', this.afs.doc('region/'+region).ref)).snapshotChanges()
    return this.afs
      .collection('dolmetscher', (ref) => ref.where('interpreter', '==', true).orderBy('lastname'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any),
            region: this.resolveReference((c.payload.doc.data() as any).region, true),
            nativelanguage: this.resolveReference((c.payload.doc.data() as any).nativelanguage, true),
            secondlanguage: this.resolveReference((c.payload.doc.data() as any).secondlanguage, true),
            thirdlanguage: this.resolveReference((c.payload.doc.data() as any).thirdlanguage, true),
            fourthlanguage: this.resolveReference((c.payload.doc.data() as any).fourthlanguage, true),
            country: this.resolveReference((c.payload.doc.data() as any).country, true),
            available: false,
            hasConflict: false,
            openRequests: 0
          }))
        )
      );
  }
  getDolmetscherListForRegion(region) {
    return (
      this.afs
        .collection('dolmetscher', (ref) =>
          ref.where('region', '==', this.afs.doc('region/' + region).ref).orderBy('lastname')
        )
        .snapshotChanges()
        // return this.afs.collection('dolmetscher', ref => ref.where('interpreter', '==', true)).snapshotChanges()
        .pipe(
          map((changes) =>
            changes.map((c) => ({
              id: c.payload.doc.id,
              ...(c.payload.doc.data() as any),
              region: this.resolveReference((c.payload.doc.data() as any).region, true),
              nativelanguage: this.resolveReference((c.payload.doc.data() as any).nativelanguage, true),
              secondlanguage: this.resolveReference((c.payload.doc.data() as any).secondlanguage, true),
              thirdlanguage: this.resolveReference((c.payload.doc.data() as any).thirdlanguage, true),
              fourthlanguage: this.resolveReference((c.payload.doc.data() as any).fourthlanguage, true),
              country: this.resolveReference((c.payload.doc.data() as any).nationalities, true)
            }))
          )
        )
    );
  }

  getRequestListForOrder(orderId) {
    return this.afs
      .collection('request', (ref) => ref.where('order', '==', this.afs.doc('order/' + orderId).ref))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any),
            dolmetscher: this.resolveReference((c.payload.doc.data() as any).dolmetscher, true),
            order: this.resolveReference((c.payload.doc.data() as any).order, false)
          }))
        )
      );
  }

  getDolmetscherDetail(id): any {
    // return this.afs.collection('dolmetscher', ref => ref.where('region', '==', this.afs.doc('region/'+region).ref)).snapshotChanges()
    return this.afs
      .collection('dolmetscher')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((c) => {
          const data: any = {
            id: c.payload.id,
            ...(c.payload.data() as any),
            region: this.resolveReference((c.payload.data() as any).region, true),
            nativelanguage: this.resolveReference((c.payload.data() as any).nativelanguage, true),
            secondlanguage: this.resolveReference((c.payload.data() as any).secondlanguage, true),
            thirdlanguage: this.resolveReference((c.payload.data() as any).thirdlanguage, true),
            fourthlanguage: this.resolveReference((c.payload.data() as any).fourthlanguage, true),
            country: this.resolveReference((c.payload.data() as any).nationalities, true)
          };
          return data;
        })
      );
  }
  setDolmetscherDetail(dolmetscher) {
    if (this.hasId(dolmetscher.regionid)) {
      dolmetscher.region = this.afs.collection('region').doc(dolmetscher.regionid).ref;
    } else {
      dolmetscher.region = null;
    }
    if (this.hasId(dolmetscher.nativelanguageid)) {
      dolmetscher.nativelanguage = this.afs.collection('language').doc(dolmetscher.nativelanguageid).ref;
    } else {
      dolmetscher.nativelanguage = null;
    }
    if (this.hasId(dolmetscher.secondlanguageid)) {
      dolmetscher.secondlanguage = this.afs.collection('language').doc(dolmetscher.secondlanguageid).ref;
    } else {
      dolmetscher.secondlanguage = null;
    }

    if (this.hasId(dolmetscher.thirdlanguageid)) {
      dolmetscher.thirdlanguage = this.afs.collection('language').doc(dolmetscher.thirdlanguageid).ref;
    } else {
      dolmetscher.thirdlanguage = null;
    }

    if (this.hasId(dolmetscher.fourthlanguageid)) {
      dolmetscher.fourthlanguage = this.afs.collection('language').doc(dolmetscher.fourthlanguageid).ref;
    } else {
      dolmetscher.fourthlanguage = null;
    }

    if (this.hasId(dolmetscher.countryid)) {
      dolmetscher.country = this.afs.collection('nationalities').doc(dolmetscher.countryid).ref;
    } else {
      dolmetscher.country = null;
    }
    if (this.hasId(dolmetscher.dolmetscherid)) {
      dolmetscher.dolmetscher = this.afs.collection('dolmetscher').doc(dolmetscher.dolmetscherid).ref;
    } else {
      dolmetscher.dolmetscher = null;
    }

    return this.afs
      .collection('dolmetscher')
      .doc(dolmetscher.id)
      .set(dolmetscher, { merge: true });
  }

  getLanguageList() {
    return this.afs
      .collection('language', (ref) => ref.orderBy('language'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  hasId(value) {
    if (!value) {
      return false;
    }

    if (value === '') {
      return false;
    }

    return true;
  }

  getCountryList() {
    return this.afs
      .collection('nationalities', (ref) => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  getCustomerList() {
    return this.afs
      .collection('customer', (ref) => ref.orderBy('name'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }
  getCustomerDetail(id) {
    // return this.afs.collection('dolmetscher', ref => ref.where('region', '==', this.afs.doc('region/'+region).ref)).snapshotChanges()
    return this.afs
      .collection('customer')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((c) => ({
          id: c.payload.id,
          ...(c.payload.data() as any)
        }))
      );
  }

  async createOrder(order) {
    order.dolmetschCategory = this.afs.doc('dolmetschCategory/' + order.dolmetschCategoryId).ref;
    order.dolmetschType = this.afs.doc('dolmetschType/' + order.dolmetschTypeId).ref;
    order.dolmetscher = null; // this.afs.doc('dolmetscher/'+order.dolmetscherId).ref
    order.language = this.afs.doc('language/' + order.languageId).ref;
    order.region = this.afs.doc('region/' + order.regionId).ref;
    order.customer = this.afs.doc('customer/' + order.customerId).ref;

    let dateFrom = moment(order.dateFrom).set('hours', order.dateFromTime.substr(0, 2));
    dateFrom = dateFrom.set('minutes', order.dateFromTime.substr(3, 2));
    dateFrom = dateFrom.set('seconds', 0);

    order.dateFrom = dateFrom.toDate();

    let dateTo = moment(order.dateTo).set('hours', order.dateToTime.substr(0, 2));
    dateTo = dateTo.set('minutes', order.dateToTime.substr(3, 2));
    dateTo = dateTo.set('seconds', 0);

    order.dateTo = dateTo.toDate();

    order._region = await this.resolveReference(order.region, true);
    order._dolmetschType = await this.resolveReference(order.dolmetschType, true);
    order._dolmetschCategory = await this.resolveReference(order.dolmetschCategory, true);
    order._dolmetscher = null; // await this.resolveReference(order.dolmetscher, true)
    order._language = await this.resolveReference(order.language, true);
    order._customer = await this.resolveReference(order.customer, true);

    return this.afs.collection('order').add(order);
  }
  async updateOder(id, order) {
    order.dolmetschCategory = this.afs.doc('dolmetschCategory/' + order.dolmetschCategoryId).ref;

    order.dolmetschType = this.afs.doc('dolmetschType/' + order.dolmetschTypeId).ref;

    if (this.hasId(order.dolmetscherId)) {
      order.dolmetscher = this.afs.doc('dolmetscher/' + order.dolmetscherId).ref;
      order.statusAssigned = true;
    } else {
      order.dolmetscher = null;
      order.statusAssigned = false;
      order.statusCleared = false;
    }
    if (order.statusCleared === false) {
      order.statusConfirmed = false;
    }

    if (this.hasId(order.languageId)) {
      order.language = this.afs.doc('language/' + order.languageId).ref;
    } else {
      order.language = null;
    }
    if (this.hasId(order.regionId)) {
      order.region = this.afs.doc('region/' + order.regionId).ref;
    } else {
      order.region = null;
    }

    if (this.hasId(order.customerId)) {
      order.customer = this.afs.doc('customer/' + order.customerId).ref;
    } else {
      order.customer = null;
    }

    let dateFrom = moment(order.dateFrom).set('hours', order.dateFromTime.substr(0, 2));
    dateFrom = dateFrom.set('minutes', order.dateFromTime.substr(3, 2));
    dateFrom = dateFrom.set('seconds', 0);

    order.dateFrom = dateFrom.toDate();
    order.dateFromMonth = dateFrom.format('YYYYMM');
    order.dateFromYear = dateFrom.format('YYYY');

    let dateTo = moment(order.dateTo).set('hours', order.dateToTime.substr(0, 2));
    dateTo = dateTo.set('minutes', order.dateToTime.substr(3, 2));
    dateTo = dateTo.set('seconds', 0);

    order.dateTo = dateTo.toDate();

    order._region = await this.resolveReference(order.region, true);
    order._dolmetschType = await this.resolveReference(order.dolmetschType, true);
    order._dolmetschCategory = await this.resolveReference(order.dolmetschCategory, true);
    order._dolmetscher = await this.resolveReference(order.dolmetscher, true);
    order._language = await this.resolveReference(order.language, true);
    order._customer = await this.resolveReference(order.customer, true);

    console.log(order);

    return this.afs
      .collection('order')
      .doc(id)
      .set(order, { merge: true });
  }
  deleteOrder(id) {
    return this.afs
      .collection('order')
      .doc(id)
      .set({ active: false }, { merge: true });
  }
  undeleteOrder(id) {
    return this.afs
      .collection('order')
      .doc(id)
      .set({ active: true }, { merge: true });
  }

  fixOrders() {
    console.log('fixOrders');
    return this.afs
      .collection('order', (ref) => ref.where('_dolmetscher', '==', null))
      .get()
      .subscribe(async (data) => {
        const batch = this.afs.firestore.batch();
        let n = 0;
        console.log('FirestoreService -> fixOrders -> data.docs', data.docs.length);
        for (const c of data.docs) {
          const d = c.data() as any;

          if (n < 400) {
            if (
              (!Boolean(d._region?.id) && Boolean(d.regionId)) ||
              (!Boolean(d._dolmetschType?.id) && Boolean(d.dolmetschTypeId)) ||
              (!Boolean(d._dolmetschCategory?.id) && Boolean(d.dolmetschCategoryId)) ||
              (!Boolean(d._dolmetscher?.id) && Boolean(d.dolmetscherId)) ||
              (!Boolean(d._language?.id) && Boolean(d.languageId)) ||
              (!Boolean(d._customer?.id) && Boolean(d.customerId))
            ) {
              console.log('fix');
              n++;

              const data1 = {
                _region: d.region ? await this.resolveReference(d.region, true) : null,
                _dolmetschType: d.dolmetschType ? await this.resolveReference(d.dolmetschType, true) : null,
                _dolmetschCategory: d.dolmetschCategory ? await this.resolveReference(d.dolmetschCategory, true) : null,
                _dolmetscher: d.dolmetscher ? await this.resolveReference(d.dolmetscher, true) : null,
                _language: d.language ? await this.resolveReference(d.language, true) : null,
                _customer: d.customer ? await this.resolveReference(d.customer, true) : null
              };

              data1._region = data1._region ? data1._region : null;
              data1._dolmetschType = data1._dolmetschType ? data1._dolmetschType : null;
              data1._dolmetschCategory = data1._dolmetschCategory ? data1._dolmetschCategory : null;
              if (data1._dolmetscher === undefined) {
                data1._dolmetscher = null;
              }
              data1._language = data1._language ? data1._language : null;
              data1._customer = data1._customer ? data1._customer : null;
              console.log(c.id, data1);

              console.log('write');
              await this.afs
                .collection('order')
                .doc(c.id)
                .update(data1);
            } else {
              console.log('ok');
            }
          }
        }
        console.log('commit');
        return batch.commit();
      });
  }

  createRequest(orderid, dolmetscherid) {
    const data = {
      order: this.afs.doc('order/' + orderid).ref,
      orderId: this.afs.doc('order/' + orderid).ref.id,
      dolmetscher: this.afs.doc('dolmetscher/' + dolmetscherid).ref,
      dolmetscherId: this.afs.doc('dolmetscher/' + dolmetscherid).ref.id,
      status: 'O',
      dateTo: new Date()
    };
    return this.afs.collection('request').add(data);
  }
  deleteRequest(requestid) {
    return this.afs
      .collection('request')
      .doc(requestid)
      .delete();
  }

  createCustomer(customer) {
    if (!customer?.supervisors) {
      customer.supervisors = [];
    }
    if (customer.email) {
      if (!customer.supervisors.includes(customer.email)) {
        customer.supervisors.push(customer.email);
      }
    }

    return this.afs.collection('customer').add(customer);
  }
  updateCustomer(id, customer) {
    if (!customer?.supervisors) {
      customer.supervisors = [];
    }
    if (customer.email) {
      if (!customer.supervisors.includes(customer.email)) {
        customer.supervisors.push(customer.email);
      }
    }

    return this.afs
      .collection('customer')
      .doc(id)
      .set(customer, { merge: true });
  }

  deleteCustomer(id) {
    return this.afs
      .collection('customer')
      .doc(id)
      .set({ active: false }, { merge: true });
  }

  resolveReference(ref, cache) {
    // return new Promise((resolve, reject) => {
    // return resolve('')
    // })

    if (!ref) {
      return null;
    }

    return FirestoreCache.queryDatabase(ref, !Boolean(cache));
  }
  // periodForGettingRBSOrders() {
  //   let duration = 1; // 1 hour interval
  //   let interval = duration * 60 * 60 * 1000 // millisekunden
  //   setTimeout(() => { this.getEventsFromRBSBern() }, interval)
  //   console.log('rbs orders automatisch abgerufen')
  // }
  // RBS Bern
  getEventsFromRBSBern() {
    return this.afs
      .collection('interface')
      .doc('rbsbern')
      .set({ lastrun: new Date() }, { merge: true });
  }
  getLastRBSUpdate() {
    return this.afs
      .collection('interface')
      .doc('rbsbern')
      .valueChanges();
  }
  getLastUpdateSuccess() {
    return this.afs
      .collection('interfaceSuccess')
      .doc('rbsorder')
      .valueChanges();
  }
  getRBSError() {
    return this.afs
      .collection('interfaceError')
      .doc('rbsorder')
      .valueChanges();
  }

  async getOpenAvailabilityForOrderAndDolmetscher(order, dolmetscher) {
    console.log(order, 'open availability');
    return this.afs
      .collection('availability', (ref) =>
        ref.where('uid', '==', dolmetscher.id).where('dateto', '>=', new Date(order.dateTo.seconds * 1000))
      )
      .get()
      .toPromise()
      .then((collection) => {
        const items = [];
        collection.docs.forEach((d) => {
          items.push(d.data());
        });
        const filtered = items.filter((item) => {
          if (item.selected !== true) {
            return false;
          }
          const a = new Date(item.datefrom.seconds * 1000);
          const b = new Date(order.dateFrom.seconds * 1000);
          if (b > a) {
            return true;
          } else {
            return false;
          }
        });

        if (filtered.length > 0) {
          return true;
        } else {
          return false;
        }
      });
  }

  getAvailabilityBetreenDates(dateFrom, dateTo) {
    return this.afs
      .collection('availability', (ref) => ref.where('date', '>=', dateFrom).where('date', '<=', dateTo))
      .get()
      .toPromise()
      .then((collection) => {
        const data = [];
        collection.forEach((item) => {
          const i = item.data() as any;
          data.push({
            id: item.id,
            key2: i.uid + '-' + i.key,
            key3: i.uid + '-' + moment(i.date.seconds * 1000).isoWeekday() + '-' + i.slot,
            ...i
          });
        });

        return data;
      });
  }
  async getNoConflictForOrderAndDolmetscher(order, dolmetscher) {
    let bool: boolean;
    console.log(order);
    return this.afs
      .collection('order', (ref) => ref.where('dolmetscherId', '==', dolmetscher.id).where('dateFrom', '>', new Date()))
      .get()
      .toPromise()
      .then((collection) => {
        console.log(collection, 'collection of order');
        collection.forEach((item) => {
          const i = item.data() as any;
          console.log(item, 'loop of single order');
          // console.log('1. start', moment.unix(item.data().dateFrom.seconds*1000) , ' nach end', moment.unix(order.dateTo.seconds*1000))
          // console.log('2. end', moment.unix(item.data().dateTo.seconds*1000) , ' vor start', moment.unix(order.dateFrom.seconds*1000))
          if (
            moment(i.dateFrom.seconds * 1000).isAfter(moment(order.dateTo.seconds * 1000)) ||
            moment(i.dateTo.seconds * 1000).isBefore(moment(order.dateFrom.seconds * 1000))
          ) {
            bool = false; // hasConflict == false, kein konflikt
            console.log('has no conflict');
          } else {
            bool = true;
          }
        });
        return bool;
      });
  }

  async getOpenRequestsForOrderAndDolmetscher(order, dolmetscher) {
    return this.afs
      .collection('request', (ref) =>
        ref
          .where('dolmetscher', '==', this.afs.doc('dolmetscher/' + dolmetscher.id).ref)
          .where('order', '==', this.afs.doc('order/' + order.id).ref)
          .where('status', '==', 'O')
      )
      .get()
      .toPromise()
      .then((collection) => {
        return collection.size;
      });
  }
  async getOpenRequestsForOrder(order) {
    return this.afs
      .collection('request', (ref) =>
        ref.where('order', '==', this.afs.doc('order/' + order.id).ref).where('status', '==', 'O')
      )
      .get()
      .toPromise()
      .then((collection) => {
        return collection.size;
      });
  }
  getOpenRequests() {
    return this.afs
      .collection('request', (ref) => ref.where('status', '==', 'O').orderBy('dateTo', 'desc'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any),
            dolmetscher: this.resolveReference((c.payload.doc.data() as any).dolmetscher, true),
            _order: this.resolveReference((c.payload.doc.data() as any).order, true)
          }))
        )
      );
  }
  cancelRequests(id) {
    this.getRequestsForCancel(id).subscribe((requests) => {
      const batch = this.afs.firestore.batch();
      console.log(requests);
      for (const request of requests) {
        batch.update(this.afs.collection('request').doc(request.id).ref, { isCancled: true });
      }
      return batch.commit();
    });
  }

  getRequestsForCancel(id) {
    return this.afs
      .collection('request', (ref) => ref.where('orderId', '==', id))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  getEmaillog() {
    return this.afs
      .collection('emaillog', (ref) => ref.orderBy('createdAt', 'desc').limit(500))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any)
          }))
        )
      );
  }

  undoOrderRelease(orderId) {
    return this.afs
      .collection('order')
      .doc(orderId)
      .set({ statusConfirmed: false }, { merge: true });
  }
  undoOrderExport(orders) {
    const batch = this.afs.firestore.batch();
    for (const order of orders) {
      console.log('reverting export');
      this.afs
        .collection('order')
        .doc(order.id)
        .ref.update({ statusExported: false });
    }
    return batch.commit();
  }

  async updateOrderAsExported(orders) {
    const date = new Date();
    const batch = this.afs.firestore.batch();
    let n = 0;

    // for (const c of orders) {
    //   c._region = (await c.region.get()).data();
    //   c._dolmetschType = (await c.dolmetschType.get()).data();
    //   c._dolmetschCategory = (await c.dolmetschCategory.get()).data();
    //   c._dolmetscher = (await c.dolmetscher.get()).data();
    //   c._language = (await c.language.get()).data();
    //   c._customer = (await c.customer.get()).data();
    // }

    for (const order of orders) {
      n++;

      console.log(n);
      if (n < 400) {
        order._region = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.region));
        order._dolmetschType = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.dolmetschType));
        order._dolmetschCategory = FirestoreCache.getDataOrNull(
          await FirestoreCache.queryDatabase(order.dolmetschCategory)
        );
        order._dolmetscher = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.dolmetscher));
        order._language = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.language));
        order._customer = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.customer));

        batch.set(
          this.afs.collection('order').doc(order.id).ref,
          {
            statusExported: true,
            statusExportedDate: date,
            _region: order._region,
            _dolmetschType: order._dolmetschType,
            _dolmetschCategory: order._dolmetschCategory,
            _dolmetscher: order._dolmetscher,
            _language: order._language,
            _customer: order._customer
          },
          { merge: true }
        );
      }
    }

    return batch.commit();
  }

  async updateExportedOrders() {
    const batch = this.afs.firestore.batch();
    const orders = this.afs
      .collection('order', (ref) => ref.where('revision', '<', 5))
      .get()
      .pipe(
        map((collection) => {
          console.log('changes');
          const items = [];
          let n = 0;
          console.log(collection.docs.length);
          collection.docs.forEach((c) => {
            const d = c.data() as any;
            n++;
            if (n < 500) {
              items.push({
                id: c.id,
                ...(d as any),
                region: this.resolveReference(d.region, true),
                dolmetschType: this.resolveReference(d.dolmetschType, true),
                dolmetschCategory: this.resolveReference(d.dolmetschCategory, true),
                dolmetscher: this.resolveReference(d.dolmetscher, true),
                language: this.resolveReference(d.language, true),
                customer: this.resolveReference(d.customer, true),
                _region: null,
                _dolmetschType: null,
                _dolmetschCategory: null,
                _dolmetscher: null,
                _language: null,
                _customer: null
              });
            }
            // }
          });

          return items;
        })
      );

    const resolved = orders.toPromise().then(async (data) => {
      for (const order of data) {
        order._region = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.region));
        order._dolmetschType = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.dolmetschType));
        order._dolmetschCategory = FirestoreCache.getDataOrNull(
          await FirestoreCache.queryDatabase(order.dolmetschCategory)
        );
        order._dolmetscher = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.dolmetscher));
        order._language = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.language));
        order._customer = FirestoreCache.getDataOrNull(await FirestoreCache.queryDatabase(order.customer));
      }

      return data;
    });

    resolved.then((data) => {
      for (const c of data) {
        // console.log('update:', c.id)
        batch.set(
          this.afs.collection('order').doc(c.id).ref,
          {
            _region: c._region,
            _dolmetschType: c._dolmetschType,
            _dolmetschCategory: c._dolmetschCategory,
            _dolmetscher: c._dolmetscher,
            _language: c._language,
            _customer: c._customer,
            revision: 5
          },
          { merge: true }
        );
      }
      return batch.commit();
    });
  }
}
