import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  // Config REF
  public message: any;
  public messages: any;

  constructor(public snackBar: MatSnackBar) {
    this.message = { title: '', message: '' };
    this.messages = [];
  }
  alertSuccess(title, message) {
    this.message.title = title;
    this.message.message = message;

    this.messages = []; // refresh, until we have a better solution
    this.messages.push(message);

    // Simple message with an action.
    const snackBarRef = this.snackBar.open(message, '', {
      duration: 3000
    });

    /*$("#success-alert").alert();
    $("#success-alert").fadeTo(2000, 500).slideUp(500, function(){
      $("#success-alert").slideUp(500);
    }); */
  }
  alertError(title, message) {
    console.log('🐙 ~ file: message.service.ts ~ line 32 ~ MessageService ~ alertError ~ title', title);
    if ((title || '').includes('PERMISSION_DENIED')) {
      return;
    }
    this.message.title = title;
    this.message.message = message;

    this.messages = []; // refresh, until we have a better solution
    this.messages.push({ title, message });

    const snackBarRef = this.snackBar.open(message, '', {
      duration: 3000
    });
    /*$("#error-alert").alert();
    $("#error-alert").fadeTo(500, 500).delay(5000).slideUp(500);

    $("#error-alert-modal").alert();
    $("#error-alert-modal").fadeTo(500, 500).delay(5000).slideUp(500);*/
  }
}
